import moment from "moment";
import React, { Component } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import { chain, titleCase } from "voca";
import ErrorAlert from "../../components/ErrorAlert";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import PageLoader from "../../components/PageLoader";
import { confirmProposal, storeActivity } from "../../services/api";
import { clearCustomer, getCustomer, getToken, isActiveSession, storeCustomer } from "../../services/storage";

class Proposals extends Component {
    state = {
        customer: {
            name: "",
            phone: "",
            document: "",
            documentType: "",
            firstName: ""
        },

        proposal: {
            dueDate: "",
            contract: "",
            details: "",
            value: 0,
            options: []
        },

        submit: {
            disabled: false,
            loading: false
        },

        error: {
            show: false,
            content: null
        },

        checkedProposal: null,
        redirect: null,
        loading: true
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    async componentDidMount() {
        if (!isActiveSession()) return this.handleLogout()

        // Get customer and proposal from storage
        const { customer, proposal, summary } = getCustomer()

        if (summary !== null) {
            return this.setState({
                redirect: {
                    pathname: "/summary",
                    state: {
                        from: this.props.location
                    }
                }
            })
        }
        
        // Store a user activity
        await storeActivity({
            token: proposal.token,
            description: "Acesso às informações da dívida e propostas"
        })

        // Set first proposal as checked
        const checkedProposal = proposal.options[0].id

        // Format the document by type
        if (customer.documentType === "CPF") {
            customer.document = customer.document
                .padStart(11, "0")
                .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4")
        } else if (customer.documentType === "CNPJ") {
            customer.document = customer.document
                .padStart(14, "0")
                .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")
        }

        // Format customer phone
        customer.phone = customer.contact.phones[0]
            ? customer.contact.phones[0].replace(/^(\d{2})(\d{4,5})/, "($1) $2-")
            : ""

        // Set customer first name
        customer.firstName = titleCase(chain(customer.name).split(/\s/, 1).value().pop())

        // Set a formatted due date
        proposal.due_date = moment(proposal.due_date, 'YYYY-MM-DD').format("DD/MM/YYYY")

        this.setState({ customer, proposal, checkedProposal, loading: false })
    }
    
    isProposalChecked = value => this.state.checkedProposal === value

    handleChange = e => this.setState({ checkedProposal: parseInt(e.target.value) })

    handleLogout = () => {
        // Get proposal token
        const token = getToken()

        // Clear customer data
        clearCustomer()
        
        if (token.length > 0) {
            // Store a user activity
            storeActivity({ token, description: "Logout através da lista de propostas" })
        }
        
        this.setState({
            redirect: {
                pathname: `/${token}`,
                state: { from: this.props.location }
            }
        })
    }

    handleSubmit = async () => {
        // Set states for submit button
        this.setState({ submit: { disabled: true, loading: true } })

        // Get proposal token from storage
        const token = getToken()

        // Get current checked proposal
        const { checkedProposal } = this.state

        try {
            // Store a user activity
            await storeActivity({ token, description: "Tentativa de confirmação de acordo" })

            // Get data from confirmation response
            const { data } = await confirmProposal({ token, proposal: checkedProposal })
            
            if (data.status) {
                // Store a user activity
                storeActivity({ token, description: "Acordo confirmado com sucesso" }).then()

                // Update customer data on local storage
                storeCustomer({ summary: data.proposal })
            
                // Redirect user to proposal summary
                this.setState({
                    redirect: {
                        pathname: "/summary",
                        state: { from: this.props.location }
                    }
                })
            } else {
                storeActivity({ token, description: "Erro ao confirmar o acordo" }).then()

                // Set states for submit button and show the error
                this.setState({
                    error: { show: true, content: data.error },
                    submit: { disabled: true, loading: false }
                })
            }
        } catch (err) {
            // Get error response status and data
            const { response: { status, data } } = err
            
            // Error state
            const error = { show: true, content: null }

            if (status === 404) {
                error.content = "Não há propostas disponíveis";
            } else if (status === 400) {
                error.content = "Esta proposta é inválida"
            } else {
                error.content = data.error
            }

            // Store a user activity
            storeActivity({ token, description: `Erro ao confirmar o acordo (${error.content})` }).then()

            // Set states for submit button and show the error
            this.setState({
                error,
                submit: { disabled: false, loading: false }
            })
        }
    }

    render = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)

        if (this.state.loading)
            return <PageLoader header={{ direction: "horizontal" }} />

        return (
            <FadeIn>
                <Header />
                <Card className="shadow-sm border-0">
                    <Container className="py-3">
                        <Row className="pb-3">
                            <Col>
                                <Card.Title>Bem-vindo(a)!</Card.Title>
                                <Card.Subtitle className="small text-muted">Confira abaixo suas informações</Card.Subtitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-2">
                                        <div className="small">Nome</div>
                                        <div>{titleCase(this.state.customer.name)}</div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="small">{this.state.customer.documentType}</div>
                                        <div>{this.state.customer.document}</div>
                                    </div>
                                    <div className="form-group mb-1">
                                        <div className="small">Telefone</div>
                                        <div>{this.state.customer.phone}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <hr />
                        <Row className="pb-3">
                            <Col>
                                <Card.Title>Dívida</Card.Title>
                                <Card.Subtitle className="small text-muted">Informações da dívida a ser negociada</Card.Subtitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-2">
                                        <div className="small">Valor da dívida</div>
                                        <div>{this.state.proposal.value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="small">Detalhe</div>
                                        <div>{this.state.proposal.details}</div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="small">Contrato(s)</div>
                                        <div>{this.state.proposal.contract}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col>
                                <Card.Title>Propostas</Card.Title>
                                <Card.Subtitle className="small text-muted">Selecione uma das propostas de negociações </Card.Subtitle>
                            </Col>
                        </Row>

                        <Row className="py-2">
                            <Col>
                                <span className="small text-danger">Propostas válidas até dia {this.state.proposal.due_date}</span>
                            </Col>
                        </Row>

                        <Row className="pb-2">
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-0">
                                        <div>
                                            {this.state.proposal.options.map((proposal, key) => (
                                                <Form.Check type="radio"
                                                    name="proposals"
                                                    id={`proposal_${key}`}
                                                    checked={this.isProposalChecked(proposal.id)}
                                                    onChange={this.handleChange}
                                                    label={proposal.description}
                                                    value={proposal.id}
                                                    key={key} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {this.state.error.show ? (<ErrorAlert {...this.state.error} />) : (<></>)}

                        <Row>
                            <Col>
                                <Alert variant="warning" className="small py-2 my-2">
                                    Ao confirmar seu acordo, o boleto para pagamento será disponibilizado imediatamente aqui no Portal de Negociação
                                </Alert>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="mt-2">
                                <Button disabled={this.state.submit.disabled} block variant="primary" onClick={this.handleSubmit}>
                                    Confirmar acordo
                                    <Loader show={this.state.submit.loading} />
                                </Button>
                                <Button block variant="link" onClick={this.handleLogout}>
                                    Sair
                                    {/* Não sou {this.state.customer.firstName} */}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </FadeIn>
        )
    }
}

export default Proposals