import moment from "moment";

const sCustomerKey = '_-cp_d-t__'

/**
 * Store customer data in storage
 * @param {string|object} data 
 * @returns 
 */
export const storeCustomer = data => {
    const customer = Object.assign(getCustomer(), data || {})
    sessionStorage.setItem(sCustomerKey, JSON.stringify(customer))
}

/**
 * Get customer data from storage
 * @returns {object}
 */
export const getCustomer = () => {
    let data = sessionStorage.getItem(sCustomerKey)
    return data !== null ? JSON.parse(data) : {}
}

/**
 * Verify if has customer data
 * @returns {boolean}
 */
export const hasCustomer = () => sessionStorage.getItem(sCustomerKey) !== null

/**
 * Clear customer data from storage
 * @returns {undefined}
 */
export const clearCustomer = () => sessionStorage.removeItem(sCustomerKey)

/**
 * Update session start time
 */
export const updateSessionStart = () => {
    // Create new session_start
    const session_start = moment()

    // Store new data on session_start
    storeCustomer(Object.assign(getCustomer(), { session_start }))

    return session_start
}

/**
 * Check if has active session
 * @returns {boolean}
 */
export const isActiveSession = token => {
    // Return false when has no customer
    if (!hasCustomer()) return false

    // Check for different token
    if (token !== undefined && getToken() !== token) return false

    // Get customer data
    const { session_start = null } = getCustomer()

    // Return false when has no session_start
    if (session_start === null) return false

    // Set session time limit
    const sessionUntil = moment(session_start).add(5, 'm')
    if (!moment().isSameOrBefore(sessionUntil)) return false

    return true
}

export const getToken = () => {
    const data = getCustomer()

    if (Object.keys(data).indexOf('proposal') > -1) {
        return data.proposal.token
    }

    return ''
}