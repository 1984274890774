import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

class Loader extends Component {
    render() {
        return (this.props.show ?? false)
            ? (<FontAwesomeIcon className="ml-2" spin icon={faCircleNotch} />)
            : <></>
    }
}

export default Loader