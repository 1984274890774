import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from './pages/Login';
import Proposals from './pages/Proposals';
import ProposalSummary from './pages/ProposalSummary';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/proposal" component={Proposals} />
            <Route exact path="/summary" component={ProposalSummary} />
            {/* <Route exact path="/reminder/:token" component={Reminder} /> */}
            <Route exact path="/:token" component={Login} />
            <Redirect path="*" to={ { pathname: '/not-found' } } />
        </Switch>
    </BrowserRouter>
);

export default Routes;