import hClaroMtexApp from "./assets/img/horizontal/claro.mtex.app.png"
import hDefault from "./assets/img/horizontal/default.png"
import hRecMtexApp from "./assets/img/horizontal/rec.mtex.app.png"
import vClaroMtexApp from "./assets/img/vertical/claro.mtex.app.png"
import vDefault from "./assets/img/vertical/default.png"
import vRecMtexApp from "./assets/img/vertical/rec.mtex.app.png"

export const logo = {
    vertical: {
        'claro.mtex.app': vClaroMtexApp,
        'negocia.claro.com.br': vClaroMtexApp,
        'default': vDefault,
        'rec.mtex.app': vRecMtexApp,
    },

    horizontal: {
        'claro.mtex.app': hClaroMtexApp,
        'negocia.claro.com.br': hClaroMtexApp,
        'default': hDefault,
        'rec.mtex.app': hRecMtexApp,
    }
}

export const getLogo = (domain, orientation) => {
    if (orientation === 'h')
        return logo.horizontal[domain] ?? logo.horizontal.default

    return logo.vertical[domain] ?? logo.vertical.default
}