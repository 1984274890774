import { Container } from 'react-bootstrap';
import Routes from './routes';
import './styles/App.scss';

const App = () => (
  <Container className="py-3">
    <Routes />
  </Container>
)

export default App;
