import axios from "axios"
import * as crypt from "./crypto"

/**
 * Get API
 */
const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

api.interceptors.request.use(config => {
    if (config.encrypted && config.encrypted === true) {
        config.headers.Accept = 'text/plain'

        if (config.data) {
            config.data = crypt.encrypt(JSON.stringify(config.data))
            config.headers['Content-Type'] = 'text/plain'
        }
    }

    return config
}, error => Promise.reject(error))

api.interceptors.response.use(response => {
    if (response.config.encrypted && response.config.encrypted === true) {
        let data = crypt.decrypt(response.data)
        
        if (typeof data === 'string') {
            data = JSON.parse(crypt.decrypt(response.data))
        }

        response.data = data
    }

    return response
}, error => Promise.reject(error))

/**
 * Create request to fetch customer name
 * @param {string} tokenProposal Token for proposal
 * @returns {AxiosPromise}
 */
export const fetchCustomerName = tokenProposal => api.get(`/proposals/${tokenProposal}/customer/name`, { encrypted: true })

/**
 * Create request to fetch proposal summary
 * @param {string} tokenProposal Token for proposal
 * @returns {AxiosPromise}
 */
export const fetchSummary = tokenProposal => api.get(`/proposals/${tokenProposal}/summary`, { encrypted: true })

/**
 * Create request to validate customer document
 * @param {Object} data Values to validate customer document
 * @returns {AxiosPromise}
 */
export const validateCustomerDocument = data => api.post('/proposals/validate-customer', data, { encrypted: true })

/**
 * Create request to confirm proposal
 * @param {Object} data Values to confirm proposal
 * @returns {AxiosPromise}
 */
export const confirmProposal = data => api.post('/proposals/confirm', data, { encrypted: true })

/**
 * Create request to register user activities
 * @param {Object} data Values for user activity
 * @returns {AxiosPromise}
 */
export const storeActivity = data => api.post('/proposals/activity', data, { encrypted: true })

/**
 * Create request to get deal barcode image
 * @param {Object} data Values for user activity
 * @returns {AxiosPromise}
 */
export const fetchBarcodeImage = token => api.post('/proposals/barcode', { token }, { encrypted: true })

export default api