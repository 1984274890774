import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";

class ErrorAlert extends Component {
    renderWithoutIcon() {
        return (
            <FadeIn>
                    <Row>
                        <Col className="my-2">
                        <Alert variant="danger" className="small m-0">
                            <div className="w-100 d-flex align-items-center">
                                {this.props.content}
                            </div>
                        </Alert>
                    </Col>
                </Row>
            </FadeIn>
        )
    }

    renderWithIcon() {
        return (
            <FadeIn>
                <Row>
                    <Col className="my-2">
                        <Alert variant="danger" className="small m-0">
                            <div className="d-flex">
                                <div className="flex-shrink-1 pr-2 d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon size="2x" icon={this.props.icon} />
                                </div>
                                <div className="w-100 pl-2 d-flex align-items-center">
                                    {this.props.content}
                                </div>
                            </div>
                        </Alert>
                    </Col>
                </Row>
            </FadeIn>
        )
    }

    render() {
        return this.props.icon
            ? this.renderWithIcon()
            : this.renderWithoutIcon()
    }
}

export default ErrorAlert