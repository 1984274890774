import { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import * as brand from '../../brandOptions'
import "./styles.scss"

class Header extends Component {
    state = {
        options: {
            logo: window.location.hostname === "rec.mtex.app" ? "placeholder" : null,
            title: "PORTAL DE NEGOCIAÇÃO",
            direction: "horizontal",
        }
    }

    componentDidMount = () => {
        this.setState(Object.assign(this.state.options, this.props))
    }

    getLogoVertical = () => brand.getLogo(window.location.hostname, 'v')

    getLogoHorizontal = () => brand.getLogo(window.location.hostname, 'h')

    renderVertical = () => (
        <Container>
            <Row>
                <Col className="text-center pt-3">
                    <img src={this.getLogoVertical()} alt="Logo" className="logo-vertical rounded-circle" />
                </Col>
            </Row>
            {this.state.options.title === false ? (<></>) : (
            <Row>
                <Col className="text-center py-4 my-2">
                    <h4 className="mb-0 font-weight-normal">{this.state.options.title ?? "PORTAL DE NEGOCIAÇÃO"}</h4>
                </Col>
            </Row>
            )}
        </Container>
    )

    renderHorizontal = () => (
        <Container className="px-0 pb-3">
            <div className="d-flex my-2">
                <div className="flex-shrink-1">
                    <img src={this.getLogoHorizontal()} alt="Logo" className="logo-horizontal rounded-circle" />
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <h5 className="mb-0 font-weight-normal">{this.state.options.title ?? "PORTAL DE NEGOCIAÇÃO"}</h5>
                </div>
            </div>
        </Container>
    )

    render() {
        const direction = this.state.options.direction ?? "horizontal"

        return direction === "horizontal"
            ? this.renderHorizontal()
            : this.renderVertical()
    }
}

export default Header