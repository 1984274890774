import { faBan } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { Button, Card, Col, Container, FormControl, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";
import MaskedInput from 'react-maskedinput';
import { Redirect } from "react-router-dom";
import * as Scroll from 'react-scroll';
import voca from "voca";
import ErrorAlert from "../../components/ErrorAlert";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import PageLoader from "../../components/PageLoader";
import { fetchCustomerName, storeActivity, validateCustomerDocument } from "../../services/api";
import { clearCustomer, isActiveSession, storeCustomer, updateSessionStart } from "../../services/storage";
import "./style.scss";

class Login extends Component {
    state = {
        loading: true,
        redirect: null,

        error: {
            page: false,
            boxed: false,
            content: null
        },

        submit: {
            disabled: true,
            loading: false
        },

        input: {
            disabled: false,
            value: "",
        },
        
        customer: {
            documentType: "",
            fullName: "",
            firstName: "",
        },
    }

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputValue = this.handleInputValue.bind(this)
        this.handleUnknown = this.handleUnknown.bind(this)
    }

    getToken() {
        const { match: { params } } = this.props
        return params.token
    }

    async componentDidMount() {
        // Get proposal token
        const token = this.getToken()

        if (isActiveSession(token)) {
            return this.setState({
                loading: false,
                redirect: {
                    pathname: "/proposal",
                    state: {
                        from: this.props.location
                    }
                }
            })
        } else {
            clearCustomer()
        }
        
        try {
            // Store a user activity
            storeActivity({ token, description: "Acesso ao formulário de login" }).then()

            // Get response data
            const { data } = await fetchCustomerName(token);

            // Create customer state values
            const customer = {
                documentType: data.documentType,
                fullName: "",
                firstName: "",
            }

            // Transform full name to title case
            customer.fullName = voca.chain(data.customer.name)
                .lowerCase()
                .titleCase()
                .value()

            // Get the customer first name
            customer.firstName = voca.chain(customer.fullName)
                .split(/\s/, 1)
                .value()
                .pop()

            this.setState({ customer, loading: false })
        } catch (err) {
            // Get the HTTP status code for the error
            const { response: { status } } = err
            
            // Error state
            const error = { page: true, boxed: false }

            if (status === 404 || status === 403) {
                error.content = "Proposta não encontrada"
            } else {
                error.content = "Serviço temporariamente indisponível. Tente novamente em alguns instantes"
            }

            this.setState({ error, loading: false })
        }
    }

    async handleSubmit() {
        // Get proposal token
        const token = this.getToken()
        
        // Set new states for input and submit elements
        this.setState({
            submit: { disabled: true, loading: true },
            input: Object.assign(this.state.input, { disabled: true }),
        })
        
        try {
            // Store a user activity
            await storeActivity({ token, description: "Tentativa de validação de documento" })

            // Get document value
            const { value } = this.state.input

            // Get customer data from validated access
            const { data: { customer, proposal, summary, status } } = await validateCustomerDocument({ token, document: value })

            // Clear customer data previously stored
            clearCustomer()

            // Set new states for input and submit elements
            this.setState({
                submit: { disabled: false, loading: false },
                input: { disabled: false, value: "" },
            })
            
            if (status === true) {
                // Store a user activity
                storeActivity({ token, description: "Documento validado com sucesso" }).then()

                // Store data for validated customer
                storeCustomer({ customer, proposal, summary })

                // Store a session start time
                updateSessionStart()

                this.setState({
                    redirect: {
                        pathname: summary !== null ? "/summary" : "/proposal",
                        state: {
                            from: this.props.location
                        }
                    }
                })
            }
        } catch (err) {
            // Store a user activity
            storeActivity({ token, description: "Erro ao tentar validar o documento" }).then()

            // Get HTTP response status
            const { response: { status } } = err
            
            // Error state
            const error = { page: false, boxed: true }

            if (status === 404 || status === 403) {
                error.content = "Proposta não encontrada ou indisponível"
            } else {
                error.content = "Serviço temporariamente indisponível. Tente novamente em alguns instantes"
            }
            
            this.setState({
                error, 
                submit: { disabled: false, loading: false },
                input: { disabled: false, value: "" }
            })
        }
    }

    handleInputValue(e) {
        const value = e.target.value
        const disabledSubmit = value.replace(/[^\d]+/, "").length < 3;

        if (!disabledSubmit) {
            Scroll.scroller.scrollTo('btnActions', {
                duration: 1500,
                delay: 100,
                smooth: true
            })
        }

        this.setState({
            input: Object.assign(this.state.input, { value }),
            submit: { disabled: disabledSubmit, loading: false },
            error: { page: false, boxed: false, content: null }
        })
    }

    handleUnknown() {
        this.setState({ redirect:  '/' })
    }

    render() {
        if (this.state.loading)
            return <PageLoader />

        if (this.state.redirect !== null)
            return <Redirect to={this.state.redirect} />

        return (
            <div>
                <Header direction="vertical" />

                <FadeIn>
                    <Card className="shadow-sm border-0">
                        <Container className="py-3">
                            {this.state.error.page
                            ? (<ErrorAlert {...this.state.error} icon={faBan} />)
                            : (<>
                                <Row>
                                    <Col>
                                        <p>Olá {this.state.customer.fullName}, venha conferir as melhores condições para negociar seus débitos.</p>
                                        <p>Confirme abaixo os 4 primeiros dígitos do seu {this.state.customer.documentType}</p>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="input-docs-validation mb-3">
                                        <FormControl as={MaskedInput}
                                            mask="1111"
                                            placeholderChar="-"
                                            pattern="[0-9]*"
                                            autoFocus 
                                            type="text"
                                            className="doc-validation-value"
                                            size="lg"
                                            onChange={this.handleInputValue}
                                            {...this.state.input} />
                                    </Col>
                                </Row>

                                {this.state.error.boxed ? <ErrorAlert {...this.state.error} /> : (<></>)}
                                
                                <Row>
                                    <Col as={Scroll.Element} name="btnActions" className="mt-2">
                                        <Button disabled={this.state.submit.disabled} block variant="primary" onClick={this.handleSubmit}>
                                            Acessar
                                            <Loader show={this.state.submit.loading} />
                                        </Button>
                                        <Button block variant="link" onClick={this.handleUnknown}>Não sou {this.state.customer.firstName}</Button>
                                    </Col>
                                </Row>
                            </>)}
                        </Container>
                    </Card>
                </FadeIn>
            </div>
        )
    }
}

export default Login