import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Container } from "react-bootstrap";
import FadeIn from "react-fade-in";
import Header from "../Header";

class PageLoader extends Component {
    render() {
        const headerProps = this.props.header ?? {}
        headerProps.direction = headerProps.direction ?? "vertical"

        return (
            <FadeIn className="page-loader">
                <Header {...headerProps} />

                <Container className="h-100 d-flex justify-content-center align-items-center py-5 my-5">
                    <FontAwesomeIcon icon={faCircleNotch} spin size="5x" />
                </Container>
            </FadeIn>
        )
    }
}

export default PageLoader