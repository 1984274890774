import AddToCalendar from "@culturehq/add-to-calendar";
import moment from "moment";
import React, { Component } from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FadeIn from "react-fade-in";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import PageLoader from "../../components/PageLoader";
import Loader from "../../components/Loader";
import { storeActivity, fetchBarcodeImage } from "../../services/api";
import { clearCustomer, getCustomer, getToken, isActiveSession } from "../../services/storage";
import "./styles.scss";

class ProposalSummary extends Component {
    state = {
        summary: {
            amount: null,
            barcode_number: null,
            description: null,
            digitable_barcode_number: null,
            due_date: null,
            installment_value: null,
        },
        calendarEvent: {
            name: "Lembrete de pagamento do acordo",
            details: "",
            location: "",
            startsAt: "2018-12-06T17:00:00-05:00",
            endsAt: "2018-12-06T18:00:00-05:00"
        },
        barcode: null,
        copied: false,
        redirect: null,
        loading: true
    }

    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this)
        this.handleCopyBarcode = this.handleCopyBarcode.bind(this)
        this.handleShowPayment = this.handleShowPayment.bind(this)
    }

    async componentDidMount() {
        if (!isActiveSession()) return this.handleLogout()

        // Get information from storage
        const { summary, proposal } = getCustomer()

        // Get proposal token from storage
        const token = getToken()

        // Get barcode image from API
        const { data: { image: barcode } } = await fetchBarcodeImage(token)

        // Set barcode image
        this.setState({ barcode })
        
        // Store a user activity
        await storeActivity({
            token: token,
            description: 'Acesso ao resumo do acordo confirmado'
        })

        // Create moment object from due date
        const dueDate = moment(summary.due_date, 'YYYY-MM-DD')

        // Set a formatted due date
        summary.due_date = dueDate.format('DD/MM/YYYY')

        // Formatted digitable barcode number
        summary.digitable_barcode_number = summary.digitable_barcode_number
            .replace(/(:?\d{11})(:?\d{1})/g, '$1 $2 ').trim()

        // Calendar event values
        const calendarEvent = {
            name: `Pagamento do acordo ${proposal.details} ${proposal.contract}`,
            details: `Lembrete do pagamento "${summary.description}" do acordo do produto ${proposal.details}, contrato ${proposal.contract}`,
            location: "",
            startsAt: dueDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endsAt: dueDate.add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss[Z]')
        };

        this.setState({ summary, calendarEvent, loading: false })
    }

    handleLogout = () => {
        const token = getToken()
        
        if (token.length > 0) {
            // Store a user activity
            storeActivity({ token, description: 'Logout através do resumo do acordo confirmado' })
        }

        // Clear customer data
        clearCustomer()
        
        this.setState({
            redirect: {
                pathname: `/${token}`,
                state: { from: this.props.location }
            }
        })
    }

    handleCopyBarcode = () => {
        this.setState({ copied: true })
        
        // Store a user activity
        storeActivity({
            token: getToken(),
            description: 'Linha digitável do código de barras copiado'
        })
    }

    handleCalendarOptionsOpen = () => {
        // Store a user activity
        storeActivity({
            token: getToken(),
            description: 'Visualização das opções de inclusão ao calendário'
        })
    }

    handleShowPayment = () => {
        window.open(`/api/proposals/${getToken()}/payment`, '_blank')
    }

    renderBarcode = () => {
        return (
            this.state.barcode !== null
            ? (<img alt="Código de barras para pagamento" src={this.state.barcode} style={{maxWidth: '320px'}} />)
            : (<Alert variant="light">
                <Loader show={true} />
                <small className="px-2">Gerando código de barras</small>
            </Alert>)
        )
    }

    render = () => {
        if (this.state.redirect !== null)
            return (<Redirect to={this.state.redirect} />)

        if (this.state.loading)
            return <PageLoader header={{ direction: "horizontal" }} />

        return (
            <FadeIn>
                <Header />
                <Card className="shadow-sm border-0">
                    <Container className="py-3">
                        <Row className="pb-3">
                            <Col>
                                <Card.Title>Seu acordo foi confirmado!</Card.Title>
                                <Card.Subtitle className="small text-muted">Abaixo as informações da negociação</Card.Subtitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-2">
                                        <div className="small">Proposta</div>
                                        <div>{this.state.summary.description}</div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="small">Vencimento</div>
                                        <div>{this.state.summary.due_date}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <hr />
                        <Row className="pb-3">
                            <Col>
                                <Card.Title>Boleto</Card.Title>
                                <Card.Subtitle className="small text-muted">Abaixo as informações do boleto para pagamento</Card.Subtitle>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-2">
                                        <div className="small">Linha digitável</div>
                                        <div>{this.state.summary.digitable_barcode_number}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form">
                                    <div className="form-group mb-2">
                                        <div className="text-center">
                                            {this.renderBarcode()}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="mt-2">
                                <Button block variant="primary" onCopy={this.handleCopyBarcode} text={this.state.summary.digitable_barcode_number} as={CopyToClipboard}>
                                    <span>{!this.state.copied ? "Copiar linha digitável" : "Copiado!"}</span>
                                </Button>

                                <Button block variant="primary" onClick={this.handleShowPayment}>
                                    Visualizar boleto
                                </Button>
                                
                                <div className="btn-block">
                                    <AddToCalendar event={this.state.calendarEvent}>
                                        Adicionar ao Calendário
                                    </AddToCalendar>
                                </div>

                                <Button block variant="link" onClick={this.handleLogout}>
                                    Sair
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </FadeIn>
        )
    }
}

export default ProposalSummary